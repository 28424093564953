import React, { useState, useEffect } from "react"
import { graphql, Link } from "gatsby"
import { Dialog } from "@blueprintjs/core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/pro-light-svg-icons/faTimes"
import { faCheck } from "@fortawesome/pro-light-svg-icons/faCheck"
import Layout from "components/layout"
import classnames from "classnames"
import SEO from "components/seo"
import { Hero } from "../components/Hero"
import { Section, Container } from "../components/Layouts"
import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer"
import Button from "../components/Buttons/Button"
import { MarketingAnalysisForm } from "../components/Forms"
import { ShareButtons } from "../components/Social"

const SingleBlog = ({ data, location }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [formSuccess, setFormSuccess] = useState(false)

  const { href, pathname } = location
  const post = data.allBlogsJson.nodes[0]
  const {
    label,
    heading,
    headingColor,
    heroImage,
    text,
    social,
    isRecommended
  } = post
  const sortedBlogs = data.allBlogs.nodes
  const recommendedBlogs = data.recommended.nodes

  const blogIndex = sortedBlogs.findIndex(blog => blog.title === post.title)
  const isFirstArticle = blogIndex === 0

  const articleClass = classnames("article-links", {
    "first-article": isFirstArticle
  })

  let prevBlog = null
  let nextBlog = null

  // check if blog exists created before current blog
  if (sortedBlogs[blogIndex - 1]) {
    prevBlog = sortedBlogs[blogIndex - 1]
  }

  // check if blog exists created after current blog
  if (sortedBlogs[blogIndex + 1]) {
    nextBlog = sortedBlogs[blogIndex + 1]
  }

  // click on eventlistener to open modals from markdown link
  const handleClick = e => {
    if (e.target.classList.contains("form__trigger")) {
      setIsModalOpen(true)
    }
  }

  useEffect(() => {
    window.addEventListener("click", handleClick)

    return () => {
      window.removeEventListener("click", handleClick)
    }
  }, [])

  // reverse heading and subheading for
  // oral surgery marketing and orthodontcis marketing blogs
  const heroHeadings = {
    heading: isRecommended ? heading : label,
    subHeading: isRecommended ? label : heading
  }
  return (
    <Layout>
      <SEO
        title={post.metaTitle}
        description={post.metaDescription}
        pathname={pathname}
        socialImage={social?.socialImage || ""}
      />
      <div className="single-blog">
        <Hero
          textColor={headingColor}
          bgImage={heroImage}
          heading={heroHeadings.heading}
          subHeading={heroHeadings.subHeading}
        />

        <Section>
          <Container>
            <MarkdownViewer className="blog-content" markdown={text} />
            {post.form?.hasForm && (
              <MarketingForm
                buttonText={post.form.buttonText}
                blurb={post.form.blurb}
                locationOrigin={href}
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                formSuccess={formSuccess}
                setFormSuccess={setFormSuccess}
              />
            )}
          </Container>
        </Section>

        <Section>
          <Container>
            <ShareButtons
              url={href}
              facebook
              linkedIn
              heading="Share this article"
            />
          </Container>
        </Section>

        <Section>
          <Container>
            <div className={articleClass}>
              {prevBlog && (
                <Button
                  buttonText="Previous Article"
                  href={prevBlog.title}
                  reverseArrow
                  className="prev-btn"
                />
              )}

              {nextBlog && (
                <Button
                  buttonText="Next Article"
                  href={nextBlog.title}
                  rightSideArrow
                  className="next-btn"
                />
              )}
            </div>
          </Container>
        </Section>

        <Section colorBack tan>
          <Container>
            <div className="recommended">
              <h2 className="color-blue mt-0">Recommended Articles</h2>

              <div className="recommended__articles">
                {recommendedBlogs.map(art => (
                  <Link key={art.title} to={art.title}>
                    <h5 className="bold">{art.label}</h5>
                    <p>{art.heading}</p>
                  </Link>
                ))}
              </div>
              <Button
                buttonText="See all"
                href={data.queryPage.nodes[0].title}
              />
            </div>
          </Container>
        </Section>
      </div>
    </Layout>
  )
}

export const MarketingForm = ({
  buttonText,
  blurb,
  locationOrigin,
  isModalOpen,
  setIsModalOpen,
  formSuccess,
  setFormSuccess
}) => {
  // reset form when modal is closed
  useEffect(() => {
    if (!isModalOpen) {
      setFormSuccess(false)
    }
  }, [isModalOpen])

  return (
    <Section>
      <Button as="button" type="button" onClick={() => setIsModalOpen(true)}>
        {buttonText}
      </Button>
      <MarkdownViewer className="mt-1" markdown={blurb} />
      <Dialog
        portalClassName="form__modal"
        canOutsideClickClose={true}
        enforceFocus={true}
        autoFocus={true}
        canEscapeKeyClose={true}
        usePortal={true}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}>
        <FontAwesomeIcon
          className="form__modal-close"
          icon={faTimes}
          onClick={() => setIsModalOpen(false)}
        />
        <>
          {formSuccess ? (
            <>
              <FontAwesomeIcon
                className="form__success-icon"
                icon={faCheck}
                color="white"
              />
              <h4>Thank You</h4>
              <p>
                We have received your form. Someone from our team will be in
                contact with you shortly.
              </p>
            </>
          ) : (
            <MarketingAnalysisForm
              origin={locationOrigin}
              onSuccess={() => setFormSuccess(true)}
            />
          )}{" "}
        </>
      </Dialog>
    </Section>
  )
}

export default SingleBlog

export const pageQuery = graphql`
  query singleBlogPage($title: String!) {
    allBlogsJson(filter: { title: { eq: $title } }) {
      nodes {
        title
        metaTitle
        metaDescription
        label
        heading
        headingColor
        heroImage
        text
        form {
          hasForm
          buttonText
          blurb
        }
        isRecommended
        social {
          socialImage
        }
      }
    }
    allBlogs: allBlogsJson(
      sort: { fields: createdAt, order: DESC }
      filter: { hasThisPage: { eq: true } }
    ) {
      nodes {
        title
      }
    }
    recommended: allBlogsJson(
      filter: { isRecommended: { eq: true }, title: { ne: $title } }
    ) {
      nodes {
        label
        heading
        title
        isRecommended
      }
    }
    queryPage: allUniquePagesJson(filter: { template: { eq: "blogs" } }) {
      nodes {
        title
      }
    }
  }
`
