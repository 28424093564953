import React from "react"
import NuvoImage from "../NuvoImage"
import HeroWithImage from "./HeroWithImage"
import HeroWithColor from "./HeroWithColor"

const Hero = ({ heading, subHeading, bgImage, bgColor, textColor }) => {
  return (
    <>
      {bgImage ? (
        <HeroWithImage
          color={textColor}
          image={bgImage}
          heading={heading}
          subHeading={subHeading}
        />
      ) : (
        <HeroWithColor heading={heading} subHeading={subHeading} />
      )}
    </>
  )
}

export default Hero
