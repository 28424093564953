import React from "react"
import {
  FacebookShareButton,
  LinkedinShareButton,
  FacebookIcon,
  LinkedinIcon
} from "react-share"

const ShareButtons = ({
  url,
  heading,
  title,
  description,
  facebook,
  linkedIn
}) => {
  return (
    <div className="social-share">
      {heading && <h5>{heading}</h5>}
      <div className="social-share__buttons">
        {facebook && (
          <FacebookShareButton url={url}>
            <div>
              <FacebookIcon size={32} round={true} />
            </div>
          </FacebookShareButton>
        )}
        {linkedIn && (
          <LinkedinShareButton url={url}>
            <div>
              <LinkedinIcon size={32} round={true} />
            </div>
          </LinkedinShareButton>
        )}
      </div>
    </div>
  )
}

export default ShareButtons
