import React from "react"
import classNames from "classnames"
import { Section, Container } from "../Layouts"

const HeroWithColor = ({ heading, subHeading, bgColor = "teal" }) => {
  const mainClasses = classNames({
    "hero__color-container": true,
    [`bg--${bgColor}`]: bgColor
  })
  return (
    <Section smallSpacing colorBack teal className={mainClasses}>
      <Container className="relative">
        <h1>{heading}</h1>
        <h2>{subHeading}</h2>
      </Container>
    </Section>
  )
}

export default HeroWithColor
